import React from "react";
import Layout from "../layouts/en-minified";
import RequestType from "../components/styleguide/RequestType";
import RequestParameters from "../components/styleguide/RequestParameters";
import RequestResponse from "../components/styleguide/RequestResponse";
import RequestGwpResponse from "../components/styleguide/RequestGwpResponse";
import RequestGapResponse from "../components/styleguide/RequestGapResponse";


const Api = props => {
    return (
        <Layout location={props.location}>

          <div className="ApiPage">
            <h1>Gift With Purchase API</h1>

            <p>Retrieve GWP and details</p>

            <RequestType path='https://tyviso.com/gwp/api?partner={ID}&market={market}' />

            <RequestParameters
              list={[
                {
                  name: 'partner',
                  desc: 'Your unique ID, identifying you as publisher',
                  type: 'String'
                },
                {
                  name: 'market',
                  desc: 'Your market target',
                  type: 'String'
                }
              ]}
            />

            <RequestGwpResponse />

            <h1>Rewards API</h1>

            <p>Retrieve rewards and details</p>

            <RequestType />

            <RequestParameters
              list={[
                {
                  name: 'partner',
                  desc: 'Your unique ID, identifying you as publisher',
                  type: 'String'
                }]}
            />

            <RequestResponse />

            <h1>Gift After Purchase API</h1>

            <p>Retrieve GAP and details</p>

            <RequestType path='https://tyviso.com/gap/api?partner={ID}' />

            <RequestParameters
              list={[
                {
                  name: 'partner',
                  desc: 'Your unique ID, identifying you as publisher',
                  type: 'String'
                }]}
            />

            <RequestGapResponse />
            
          </div>
          
        </Layout>
    );
};

export default Api;