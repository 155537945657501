import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import style from './RequestResponse.module.css'

const RequestResponse = ({ className }) => {
  const classNames = `${style.root} ${className ? className : ''}`;

  return (
    <div className={classNames}>
      <h2>JSON get data example</h2>
      <p>This is an example of how to get rewards with every option included.</p>

      <SyntaxHighlighter language="javascript" style={dark}>
        {`
          {
            "template": Int(1) || Int(2) || Int(3) || Int(4),
            "headerTemplate": Int(1) || Int(2),
            "header": {
              "title": "String",
              "description": "String",
              "steps": [
                {
                  "text": "String",
                  "img": "String"
                },
                {
                  "text": "String",
                  "img": "String"
                },
                {
                  "text": "String",
                  "img": "String"
                }
              ],
              "hideHeader": "Boolean"
            },
            "settings": {
              "ctaLabel": "String",
              "ctaColor": "String",
              "ctaBg": "String",
              "headerBg": "String",
              "headerTextColor": "String",
              "terms": "String",
              "notWorking": "Boolean",
              "customCss": "String",
              "bottomArrows": "Boolean"
            },
            "offers": {
              "hero": [Offer?],                    // maximum one offer
              "popular": [Offer?, Offer?, Offer?],   // maximum of 3 offers
              "all": Offer[]
            }
          }
        `}
      </SyntaxHighlighter>
      
      <h2>JSON Offer data</h2>
      <p>Here is the template for the offer's payload</p>
      
      <SyntaxHighlighter language="javascript" style={dark}>
        {`
          {
            id: String,
            partner: String,
            partnerLogo: String,
            terms: String,
            url: String,
            title: String,
            description: String,
            image: String,
            squareImage: String,
            offerInfoButtonDiscount: String,
            offerInfoButtonDescription: String,
            offerInfoButtonCta: String,
            offerDiscountCode: String,
            offerDiscountCodeType: String,
            cta: String
          }
        `}
      </SyntaxHighlighter>
    </div>
  )
}

export default RequestResponse
